<template>
  <div v-if="form" style="max-width: 420px">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-row dense>
              <label class="text-field-label">Mission Icon</label>
              <upload-icon
                :image-source="mission && mission.icon ? mission.icon : null"
                @change="onChangeImage"
              />
              <div
                class="ml-5 v-messages theme--light error--text"
                v-if="form.$getError('icon')"
              >
                {{ form.$getError('icon')[0] }}
              </div>
            </v-row>
          </v-col>
          <v-col class="mr-4">
            <v-row>
              <label class="text-field-label">Mission No.</label>
              <v-text-field
                flat
                solo
                class="mt-2"
                v-model="form.number"
                :error-messages="form.$getError('number')"
              />
            </v-row>
            <v-row>
              <label class="text-field-label">Mission Color</label>
              <v-text-field
                flat
                solo
                class="mt-2"
                v-model="form.color"
                readonly
                :error-messages="form.$getError('color')"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menu"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="form.color" hide-inputs flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <label class="text-field-label">Mission Name</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.title"
              :error-messages="form.$getError('title')"
            />

            <label class="text-field-label">SDG Name</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.name"
              :error-messages="form.$getError('name')"
            />

            <label class="text-field-label">SDG Description</label>
            <v-textarea
              no-resize
              flat
              solo
              class="mt-2"
              rows="6"
              v-model="form.description"
              :error-messages="form.$getError('description')"
            />

            <div class="mt-4 d-flex justify-space-between">
              <v-btn
                color="yellow"
                depressed
                class="px-6"
                height="40px"
                @click="submit"
                :loading="form.$busy"
                >Save</v-btn
              >

              <v-btn
                v-if="mission"
                color="red"
                dark
                depressed
                class="px-6"
                height="40px"
                @click="showDialog = true"
                :loading="form.$busy"
                >Delete</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="showDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete ?</v-card-title>

        <v-card-text
          >Are you sure you want to delete this mission ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="deleteDialog = false" :loading="form.$busy"
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="deleteMission" :loading="form.$busy"
            >Yes, Delete please</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@/utils/form'
import Compressor from 'compressorjs'
import UploadIcon from './UploadIcon.vue'

export default {
  name: 'MissionForm',

  components: {
    UploadIcon
  },

  data() {
    return {
      isLoading: false,
      showDialog: false,
      form: null,
      menu: false,
      imageChanged: false
    }
  },

  computed: {
    ...mapState({
      mission: state => state.mission.missionDetails
    }),

    swatchStyle() {
      const { form, menu } = this
      return {
        backgroundColor: form.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },

  methods: {
    ...mapActions({
      getMissions: 'mission/getMissions',
      getMissionDetails: 'mission/getMissionDetails',
      createMission: 'mission/createMission',
      updateMission: 'mission/updateMission',
      deleteMission: 'mission/deleteMission'
    }),

    ...mapMutations({
      clearMissionDetails: 'mission/clearMissionDetails',
      callSnackbar: 'callSnackbar'
    }),

    async getMission() {
      this.isLoading = true
      await this.getMissionDetails(this.$route.params.id)
      this.form = new Form(this.mission)
      this.isLoading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      let data = this.getFormData()

      if (!this.mission) {
        await this.createMission(data)
          .then(() => {
            this.form.$busy = false
            this.callSnackbar({
              state: true,
              text: 'Successfully added new mission',
              color: 'green'
            })
            this.$router.replace({
              name: 'missions'
            })
          })
          .catch(err => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      } else {
        data.append('_method', 'put')

        await this.updateMission(data)
          .then(() => {
            this.form.$busy = false
            this.callSnackbar({
              state: true,
              text: 'Successfully update mission',
              color: 'green'
            })
            this.$router.replace({
              name: 'missions'
            })
          })
          .catch(err => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      }
    },

    async deleteMission() {
      this.form.$busy = true
      await this.mission.delete().then(async () => {
        this.form.$busy = false
        this.showDialog = false
        this.callSnackbar({
          state: true,
          text: 'Successfully delete mission',
          color: 'green'
        })
        this.$router.replace({
          name: 'missions'
        })
      })
    },

    getFormData() {
      let data = new FormData()

      data.append('number', this.form.number)
      data.append('title', this.form.title)
      data.append('name', this.form.name)
      data.append('description', this.form.description)
      data.append('color', this.form.color)

      if (this.imageChanged) {
        data.append('icon', this.form.icon)
      }

      return data
    },

    onChangeImage(blob) {
      this.imageChanged = true
      this.form.$clearErrors()

      if (blob.size < 4096) {
        this.form.icon = blob
      } else if (blob.size < 1000000) {
        let _this = this
        // eslint-disable-next-line
        new Compressor(blob, {
          quality: 0.2,
          success(result) {
            _this.form.icon = result
          }
        })
      } else {
        this.form.$setErrors({ icon: ['Image size is too large'] })
      }
    }
  },

  created() {
    if (this.$route.params.id) {
      this.getMission()
    } else {
      this.form = new Form({
        number: '',
        title: '',
        name: '',
        description: '',
        color: '#FFFFFF',
        icon: ''
      })
    }
  },

  destroyed() {
    this.clearMissionDetails()
  },

  watch: {
    $route() {
      this.getMission()
    }
  }
}
</script>
